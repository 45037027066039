
import Vue from "vue";
import ProfileData from "@/components/Profile/ProfileData.vue";
import MentorStatistics from "@/components/Mentor/MentorStatistics.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentorStatisticsPage",
  components: {
    ProfileData,
    MentorStatistics
  },
  computed: {
    ...mapGetters({
      mentor: "mentor/getMentor",
      statistics: "mentor/getStatistics"
    })
  },
  mounted(): void {
    this.$store
      .dispatch("mentor/GET_MENTOR", {
        id: this.$route.params.id
      })
      .then(() => {
        this.$store.dispatch("mentor/GET_STATISTICS", this.$route.params.id);
      });
  }
});
